body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'font1';
  src:  url(./img/font1.otf);
}
@font-face {
  font-family: 'font2';
  src:  url(./img/font2.otf);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.logo
{
  height: 50px;
}
.m1{color: #555;text-decoration: none;}
.heading1{background:linear-gradient(45deg,#fff,#fff,#f00,#fff,#fff);color: aliceblue;text-align: center;}
.pd{padding: 10px;}
.h2 {font-family: font1;animation: color-change 2s infinite;font-weight: bold;-webkit-text-stroke: 1px black;}
.h21 {font-family: font2;color:#0FFCBE;text-shadow: #000 0px 0px 3px,   #000 0px 0px 3px,   #000 0px 0px 3px; text-align: center;}
@keyframes color-change {0% { color:#fff; }50% { color: #ff0; }100% { color:#6127ad; }}
.sl11{height: 500px;width: 100%;}
.ct{border-top:5px solid cornflowerblue;border-radius:20px;border-bottom:2px solid cornflowerblue;background:#000;opacity: 0.8;text-shadow: 2px 2px 10px #00f;}
.call{position: fixed; bottom: 10px; right: 10px;z-index: 999;background-color: #fff; border-radius: 50%;padding: 8px;box-shadow: 2px 2px 10px #000;}
.client{filter: grayscale(100);transition: transform .7s ease-in-out;}.client:hover{filter: grayscale(0);transform: rotate(360deg);}
.ft{color: #fff;}
.link{text-decoration: none; color: #fff;transition: all 0.5s ease;}
.link:hover{margin-left: 20px;}
.galleryImg{
   animation:move 3s infinite ease-in-out;
}

@keyframes move{
  0% {
    transform: scale(1,1);
}
50% {
    transform: scale(1.2,1.2);
}
100% {
    transform: scale(1,1);
}

}